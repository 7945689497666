import React, { useState } from 'react';
import { FaAngleUp } from 'react-icons/fa'
import { useDocumentScrollThrottled } from '../../../utils/functions'

import { Button } from './styles'

const ScrollUpButton:React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean | string>(false);

  useDocumentScrollThrottled((callbackData: { previousScrollTop: any; currentScrollTop: any; }) => {
    const MINIMUM_SCROLL = 300;
    const TIMEOUT_DELAY = 50;
    const { currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setIsVisible(isMinimumScrolled ? true : ( !isVisible ? false : 'hide' ));
    }, TIMEOUT_DELAY);
  });

  return (
    <Button 
      to="home"
      spy={true}
      smooth={true}
      offset={-100}
      duration={800}
      className="nav-link"
      data-visible={isVisible}>
      <FaAngleUp />
    </Button>
  );
};

export default ScrollUpButton;