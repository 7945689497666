import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2em 0;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 1em;
  margin-bottom: .5em;
  max-width: 75.25em;
  justify-content: space-between;
`;

export const NewFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  margin: 1em;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 /.06);

  & .img-container { 
    max-height:13.25em;
    overflow:hidden;

    & img {
      width: 100%;
    }
  }

  & .new-title {
    & h3 {
      font-size: 1.2rem;
      color: var(--primary-color);
      margin-top: .3em;
      margin-bottom: 0;
    }
  }

  & .new-preview {
    margin-top: 1em;
    text-align: justify;
    text-justify: inter-word;
  }

  & .continue-link {
    text-align: center;
    margin-top: 1.5em;
    transition: .3s;
    color: #999;

    &:hover {
      color: var(--primary-color)
    }
  }

  @media (min-width: 47.5em) {
    max-width: calc(50% - 4em);
    margin: 1em auto;
  }

  @media (min-width: 71.25em) {
    max-width: 19em;
    margin: 1em;
  }

  @media (min-width: 85em) {
    max-width: 22em;
  }
`;