import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'

import Main from '../../components/News/Main'
import Article from '../../components/News/Article'
import texts from '../../utils/texts'

interface RouteParams {
  id?: string
}

const News:React.FC = () => {
  const params = useParams<RouteParams>();

  useEffect(() => {
    document.title = texts.news.browser_title
  }, []);

  if (params.id !== undefined) {
    return (
      <>
        <Article id={params.id} />
      </>
    );
  } else {
    return (
      <Main />
    );
  }
}

export default News;