import styled from 'styled-components';
import { Link as Scroll } from 'react-scroll';

export const Button = styled(Scroll)`
  position: fixed;
  width: 3em;
  height: 3em;
  background-color: rgb(0 0 0/.4);
  color: #fff;
  bottom: 1em;
  right: -3em;
  border-radius: 50%;
  border: none;
  padding: 0;
  transition: .3s;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-visible="true"] {
    right: 1em;
    opacity: 1;
    animation: showButton .5s;
  }

  &[data-visible="hide"] {
    animation: hideButton .5s;
  }

  &:hover {
    background-color: var(--secondary-color);
  }

  &:active, &:focus {
    outline: none;
  }

  & svg {
    font-size: 1.6rem;
  }

  @keyframes showButton {
    0% { right: -3em; }
    10% { right: -3em; opacity: 0; }
    100% { right: 1em; opacity: 1; }
  }

  @keyframes hideButton {
    0% { right: 1em; opacity: 1; }
    90% { right: -3em; opacity: 0; }
    100% { right: -3em; }
  }
`;