import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

export const BannerContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
`;

export const BannerLegend = styled.div`
  display: none;
  position: absolute;
  z-index: 3;
  bottom: 1.5em;
  width: 100%;
  text-align: center;
  color: #fff;
  opacity: .5;

  & span {
    padding: .3em .8em;
    background-color: rgba(0,0,0,1);
    border-bottom-left-radius: .7em;
    border-top-right-radius: .7em;
    font-size: 1.2rem;
  }

  @media (min-width: 68.125em) {
    display:block;
  }
`;

export const StyledCarousel = styled(Carousel)`
  max-height: 100vh;
  box-sizing: border-box;

  & > div {
    max-height: 100vh;
  }

  & .control-dots {
    margin-bottom: 3.5em;
    z-index: 3;

    & .dot {
      height: 1em;
      width: 1em;
      background-color: var(--third-color);

      &:active, &:focus {
        outline: none;
        border: transparent;
      }
    }
  }
`;

export const BannerImagem = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100vh;

  &::before {
    content: " ";
    min-height: 100vh;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    opacity: .6;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAABGdBTUEAALGPC/xhBQAAABJJREFUCB1jYECA/wgmlAUXAQAkAwH/DVk11gAAAABJRU5ErkJggg==");
  }

  & .bg-color {
    content: " ";
    min-height: 100vh;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    background: #000;
    opacity: .5;
  }

  & img {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    object-fit: cover;
    user-select: none;
  }
`;

export const Apresentation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  color: #fff;
  opacity: 1;

  & div {
    max-width: 58.75em;
    margin: 0 auto;

    & h1 {
      font-size: 2.2rem;
      margin: 0;
      transition: .3s;
    }

    & p {
      margin: 0;
      font-size: 1.2rem;
      transition: .3s;
    }
  }
   

  @media (min-width: 68.125em) {
    & div {
      & h1 {
        font-size: 3rem;
      }

      & p {
        font-size: 1.3rem;
      }
    }
  }
`;

export const SliderButton = styled.button`
  position: absolute;
  z-index: 3;
  bottom: 1em;
  cursor: pointer;
  border: transparent;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  height: 3em;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  transition: .3s;

  &:hover {
    background-color: var(--secondary-color);
  }

  &:active, &:focus {
    outline: none;
    border: transparent;
  }

  & svg {
    font-size: 1.5rem;
    margin: 0;
    border-radius: 50%;
    transition: .3s;
  }

  @media (min-width: 68.125em) {
    height: 4em;
    width: 4em;
    top: 50%;
    bottom: auto;
  }
`;