import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa'

import { Wrapper, Modal, Button } from './styles';
import texts from '../../../utils/texts';

const EmailAlertModal:React.FC = () => {
  const [ isVisible, setIsVisible ] = useState<boolean>(false);
  const [ checked, setChecked ] = useState<boolean>(false);

  const closeModal = () => {
    setIsVisible(false);
    localStorage.setItem('@aspecir-emprestimos/email-alert-modal', new Date().toDateString());
  };

  // Check if the alert was already been seen
  useEffect(() => {
    if (!checked) {
      let alreadyClecked = localStorage.getItem('@aspecir-emprestimos/email-alert-modal');

      if (alreadyClecked) {
        let checkedDate = new Date(alreadyClecked);
        checkedDate.setDate(checkedDate.getDate() + 1);

        if (checkedDate < new Date()) {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }

    setChecked(true);
  }, [checked]);

  return (
    <>
      { isVisible ? (
        <Wrapper>
          <Modal>
            <div className="modal-header">
              <h2>{texts.home.modal_alert.title}</h2>
              <Button onClick={closeModal}><span>{texts.home.modal_alert.exit_button}</span><div className="button-icon"><FaTimes /></div></Button>
            </div>
            <div className="modal-content">
              <div className="modal-main" dangerouslySetInnerHTML={{ __html :texts.home.modal_alert.content }} />
            </div>
          </Modal>
        </Wrapper>
      ) : (
        null
      )}
    </>
  );
};

export default EmailAlertModal;