import React, { useState, useEffect } from "react";
import { Wrapper, Container, FilesContainer, TransparencyText } from "./styles";
import axios from "axios";

import consts from "../../utils/config";
import texts from "../../utils/texts";

import { FileType } from "../../@types/transparency";
import Loader from "../../components/Loader";
import FileFrame from "../../components/Transparency/FileFrame";

const Transparency: React.FC = () => {
  const [files, setFiles] = useState<FileType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = texts.transparency.browser_title;
  }, []);

  useEffect(() => {
    // Return site data from the API
    axios
      .get(consts.getApiUrl() + "/arquivos?slug=transparency")
      .then((resp) => {
        setFiles(resp.data.files);
        setIsLoading(false);
      })
      .catch((resp) => {
        console.log(resp);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Wrapper>
        <Container>
          {Object.values(texts.transparency.texts).map(
            (text: { title: string; text: string }) => (
              <TransparencyText>
                <h2 className="section-title">{text.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: text.text }} />
              </TransparencyText>
            )
          )}

          {files && files.length > 0 && (
            <>
              <h2 className="section-title">{texts.transparency.title}</h2>
              <FilesContainer>
                {Object.values(files).map((item: FileType, index) => (
                  <FileFrame file={item} key={index} />
                ))}
              </FilesContainer>
            </>
          )}
        </Container>
      </Wrapper>
    );
  }
};

export default Transparency;
