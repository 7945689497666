import React from 'react';
import { Container } from './styles'
import texts from '../../../utils/texts'

const AboutUs:React.FC = () => {
  let cTexts = texts.home.about_us;

  return (
    <Container id="about-us">
      <div className="title">
        <h2 className="section-title">{cTexts.title}</h2>
      </div>
      <div className="image-container">
        <img src="/assets/imgs/about-us.png" alt="Sobre a instituição"/>
      </div>
      <div>
        <p className="content-text">{cTexts.content}</p>
      </div>
    </Container>
  );
};

export default AboutUs;