export const Menu = [
  {
    label: "Home",
    path: "home",
    block: "start",
    route: "/"
  },
  {
    label: "Produtos",
    path: "products",
    block: "center",
    route: "/produtos"
  },
  {
    label: "Notícias",
    path: "news",
    block: "center",
    route: "/noticias"
  },
  {
    label: "Contato",
    path: "contact",
    block: "center",
    route: "/?to=contact"
  },
  {
    label: "Transparência",
    route: "/transparencia"
  },
  {
    label: "Trabalhe Conosco",
    route: "/trabalhe-conosco"
  }
];