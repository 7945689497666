import React from "react";
import {
  BannerContainer,
  BannerLegend,
  SliderButton,
  BannerImagem,
  StyledCarousel,
  Apresentation,
} from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";

import { BannerType } from "../../../@types/home";
import texts from "../../../utils/texts";

// Generates the banners rows
const createCarouselItemImage = (
  index: string | number,
  banner: BannerType
) => (
  <>
    <BannerImagem key={index}>
      <div className="bg-color"></div>
      <img src={banner.image} alt={banner.title} />
    </BannerImagem>
    <Apresentation>
      <div>
        <h1>{banner.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: banner.content }}></p>
      </div>
    </Apresentation>
  </>
);

// Prev banner button
const prevButton = (
  onClickHandler: () => void,
  hasPrev: boolean,
  label: string
) =>
  hasPrev && (
    <SliderButton
      type="button"
      onClick={onClickHandler}
      title={label}
      style={{ left: 15 }}
    >
      <IoMdArrowRoundBack />
    </SliderButton>
  );

// Next banner button
const nextButton = (
  onClickHandler: () => void,
  hasNext: boolean,
  label: string
) =>
  hasNext && (
    <SliderButton
      type="button"
      onClick={onClickHandler}
      title={label}
      style={{ right: 15 }}
    >
      <IoMdArrowRoundForward />
    </SliderButton>
  );

const Banner: React.FC<BannerType[]> = (banners) => {
  return (
    <BannerContainer>
      <StyledCarousel
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={true}
        stopOnHover={true}
        showIndicators={false}
        showStatus={false}
        interval={7000}
        transitionTime={1000}
        renderArrowPrev={prevButton}
        renderArrowNext={nextButton}
      >
        {Object.values(banners).map((banner, index) =>
          createCarouselItemImage(index, banner)
        )}
      </StyledCarousel>
      <BannerLegend>
        <span>{texts.home.banner.subtitle}</span>
      </BannerLegend>
    </BannerContainer>
  );
};

export default Banner;
