import React, { useState } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import axios from "axios";
import { MdLocationOn } from "react-icons/md";
import { FaEnvelope, FaPhoneAlt, FaBriefcase } from "react-icons/fa";

import { Wrapper, Container } from "./styles";
import config from "../../../utils/config";
import texts from "../../../utils/texts";
import { setPhoneMask } from "../../../utils/functions";
import { ContactType, ContactForm } from "../../../@types/home";

const Contact: React.FC<ContactType> = ({ address, contacts }) => {
  const [formData, setFormData] = useState<ContactForm>({
    siteId: 4,
  } as ContactForm);
  const [, setValidationMessage] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  let cTexts = texts.home.contact;

  const handleForm = (e: any) => {
    e.preventDefault();
    axios
      .post(config.getContactUrl(), formData, {
        headers: { Accept: "application/json" },
      })
      .then(function (response) {
        setSuccessMsg("Mensagem enviada!");
        setFormData({ name: "", email: "", phone: "", siteId: 4 });
      });
  };

  const handleFields = (e: any, mask: string) => {
    if (mask === "phone") {
      setFormData({
        ...formData,
        [e.target.name]: setPhoneMask(e.target.value),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    setValidationMessage("");
  };

  return (
    <Wrapper id="contact">
      <h2 className="section-title">{cTexts.title}</h2>
      <Container>
        <div className="contact-information">
          <div className="get-in-touch">
            <h3>Atendimento das 9h às 17h</h3>
            <hr />
          </div>
          <div className="contact-box">
            <div className="icon">
              <MdLocationOn size={32} />
            </div>
            <div>
              <h4>Endereço</h4>
              <p>
                {address.address} - {address.complement}
              </p>
              <p>
                {address.district}, {address.cep}, {address.city}/
                {address.state}
              </p>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <FaEnvelope size={27} />
            </div>
            <div>
              <h4>E-mail</h4>
              <a href={"mailto:" + contacts.email} title="Email">
                {contacts.email}
              </a>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <FaPhoneAlt size={27} />
            </div>
            <div>
              <h4>Telefone</h4>
              <p>
                {contacts.phone}
                <b
                  style={{
                    color: "#777",
                    paddingLeft: "25px",
                    // fontWeight: "700",
                  }}
                >
                  SAC 0800-6432378
                </b>
              </p>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <FaBriefcase size={27} />
            </div>
            <div>
              <h4>Quer trabalhar conosco?</h4>
              <p>Venha fazer parte do nosso grupo!</p>
              <p>
                Envie seu currículo para:{" "}
                <a href="mailto:vagas@grupoaspecir.com.br">
                  vagas@grupoaspecir.com.br
                </a>
              </p>
            </div>
          </div>
        </div>

        <ValidationForm id="contactForm" onSubmit={(e: any) => handleForm(e)}>
          {successMsg !== "" ? (
            <div className="contactMsg">
              <span>{successMsg}</span>
            </div>
          ) : null}
          <div className="row">
            <div className="form-group">
              <TextInput
                name="name"
                id="name"
                required
                successMessage=""
                errorMessage={{
                  required: "Por favor, informe seu nome.",
                }}
                className="form-control"
                placeholder="Nome"
                autoComplete="off"
                onChange={handleFields}
              />
            </div>

            <div className="form-group">
              <TextInput
                name="phone"
                id="phone"
                type="tel"
                required
                successMessage=""
                errorMessage={{
                  required: "Por favor, informe seu telefone.",
                  type: "Este não é um telefone válido!",
                  minLength: "Necessário informar 10 caracteres, no mínimo",
                }}
                className="form-control"
                minLength={14}
                maxLength={15}
                placeholder="Telefone"
                autoComplete="off"
                onChange={(e: any) => handleFields(e, "phone")}
                value={formData.phone}
              />
            </div>

            <div className="form-group">
              <TextInput
                name="email"
                id="email"
                type="email"
                required
                successMessage=""
                errorMessage={{
                  required: "Por favor, informe seu e-mail.",
                  type: "Este não é um e-mail válido!",
                }}
                className="form-control"
                placeholder="E-mail"
                autoComplete="off"
                onChange={handleFields}
              />
            </div>

            <div className="form-group">
              <TextInput
                name="message"
                id="description"
                multiline
                placeholder="Sua Mensagem"
                className="form-control"
                required
                successMessage=""
                errorMessage={{
                  required: "Por favor, escreva sua mensagem.",
                }}
                rows="5"
                autoComplete="off"
                onChange={handleFields}
              />
            </div>

            <button type="submit" className="primary-button">
              Enviar Mensagem
            </button>
          </div>
        </ValidationForm>
      </Container>
    </Wrapper>
  );
};

export default Contact;
