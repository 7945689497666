import { getEnv } from './functions'

let siteId = 4;
const url = getEnv("REACT_APP_API_URL");

export default {
    PAGE: "aspecir_emprestimos",
    APP_NAME: "Aspecir Empréstimos",
    LOGO: '/assets/imgs/aspecir-emprestimos-logo.svg',

    getApiSiteUrl() {
        return url + `/sites/${siteId}`;
    },

    getContactUrl() {
        return url + '/contato';
    },

    getApiNewUrl() {
        return url + '/noticias';
    },

    getApiUrl() {
        return url;
    },

    getApiNewsUrl(page:number, perPage:number) {
        return `${url}/sites/${siteId}/noticias?page=${page}&per_page=${perPage}`;
    },

    getApiChatBotUrl() {
        return url + '/chat_bot';
    },

    getHolidaysUrl() {
        return `${url}/holidays_config?active_holiday=true`;
    }
}