import React from 'react';
import { FaFile, FaDownload } from 'react-icons/fa'

import { Frame } from './styles'
import texts from '../../../utils/texts';
import { FileType } from '../../../@types/transparency'

const FileFrame:React.FC<{file:FileType}> = ({file}) => {
  return (
    <Frame className={file.description && file.description !== '' ? 'frame-complete' : 'frame-simple'}>
      <div className="icon-container"><a href={file.path} target="_blank" rel="noopener noreferrer"><FaFile /></a></div>
      <div className="file-content">
        <div className="file-information">
          <div><strong>{texts.transparency.file_frame.file_name}</strong>{file.name}</div>
          <div><strong>{texts.transparency.file_frame.file_type}</strong>{file.extension}</div>
          <div><a href={file.path} target="_blank" rel="noopener noreferrer"><FaDownload /> Download</a></div>
        </div>
        <div className="file-description"><small>{texts.transparency.file_frame.file_description}</small><p>{file.description}</p></div>
      </div>
    </Frame>
  );
}

export default FileFrame;