import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

import Loader from '../../Loader'
import consts from '../../../utils/config'
import { Wrapper, Container, NewFrame } from './style'
import texts from '../../../utils/texts';
import { NewType, NewsResponse } from '../../../@types/news'

const Main:React.FC = () => {
  const [ newsData, setNewsData ] = useState<NewsResponse>({} as NewsResponse);
  const [ page ] = useState<number>(1);
  const [ perPage ] = useState<number>(10);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);

  useEffect(() => {
    // Return site data from the API
    axios.get(consts.getApiNewsUrl(page, perPage))
      .then(resp => {
        setNewsData(resp.data.data.posts);
        setIsLoading(false);
      }).catch(resp => console.log(resp));
    }, [page, perPage])

  const newImage = (item:NewType) => {
    return item.image ? <img src={item.image.url} alt={item.image_description} /> : "";
  };

  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <Wrapper id="news">
        <h2 className="section-title">{texts.news.title}</h2>
        <Container>
          {Object.values(newsData.data).map((item:NewType, index) => (
            <NewFrame key={index}>
              <div className="img-container">{ newImage(item) }</div>
              <div className="new-title">
                <h3>{item.title}</h3>
              </div>
              <small>{item.created_at}</small>
              <p className="new-preview">{item.preview}...</p>

              <Link to={'/noticias/' + item.id} className="continue-link">{texts.news.continue_button}</Link>
            </NewFrame>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

export default Main;