import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0/.8);
  }
`;

export const Modal = styled.div`
  position: relative;
  z-index: 11;
  max-width: 35.25em;
  margin: 1em;
  max-height: 90%;
  overflow-y: auto;

    & .modal-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: .2em;

      & h2 {
        color: #f1f1f1;
        font-size: 1.3rem;
        margin: 0 0 .1em .2em;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

  & .modal-content {
    background-color: var(--third-color);
    padding: 1em;
    border-bottom-left-radius: .7em;
    border-top-right-radius: .7em;

    & p {
      color: #fff;
      font-size: 19px;
    }

    & .modal-main {
      text-align: justify;
      text-justify: inter-word;
      color: #fff;
    }
  }

  @media (min-width: 35.25em) {
    & .modal-header {
      margin-bottom: 0;
      & h2 {
        font-size: 2rem;
      }
    }
  }
`;

export const Button = styled.button`
  margin-left: 1em;
  height: 2em;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: transparent;
  border-radius: .5em;
  padding: 0;
  color: var(--third-color);
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;

    & .button-icon {
      background-color: var(--primary-color);
    }
  }

  & span {
    display: none;
    margin: 0 .5em;
  }

  & .button-icon {
    background-color: var(--third-color);
    color: #f1f1f1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 .5em;
    border-radius: .5em;
    transition: .3s;
  }

  @media (min-width: 35.25em) {
    margin-top: .2em;
    background-color: #f1f1f1;
    margin-right: .2em;

    & span {
      display: block;
    }

    & .button-icon {
      margin-right: -.2em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;