import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i");

  /*** NORMALIZE ***/
  * {box-sizing:border-box;}
  html, body {height: 100%;}
  html { line-height: 1.15; -webkit-text-size-adjust: 100%; }
  body { margin: 0; }
  main { display: block; }
  h1 { font-size: 2em; margin: 0.67em 0; }
  hr { box-sizing: content-box; height: 0; overflow: visible; border-top:none; border-left: none; }
  pre { font-family: monospace, monospace; font-size: 1em; }
  a { background-color: transparent; }
  abbr[title] { border-bottom: none; text-decoration: underline; text-decoration: underline dotted; }
  b, strong { font-weight: bolder; }
  code, kbd, samp { font-family: monospace, monospace; font-size: 1em; }
  small { font-size: 80%; opacity: .8; }
  sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
  sub { bottom: -0.25em; }
  sup { top: -0.5em; }
  img { border-style: none; }
  button, input, optgroup, select, textarea { font-family: inherit; font-size: 100%; margin: 0; }
  button, input { overflow: visible; }
  button, select { text-transform: none; }
  button, [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }
  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }
  button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }
  fieldset { padding: 0.35em 0.75em 0.625em; }
  legend { box-sizing: border-box; color: inherit; display: table; max-width: 100%; padding: 0; white-space: normal; }
  progress { vertical-align: baseline; }
  textarea { overflow: auto; }
  [type="checkbox"], [type="radio"] { box-sizing: border-box;  padding: 0;  }
  [type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }
  [type="search"] { -webkit-appearance: textfield; outline-offset: -2px; }
  [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
  ::-webkit-file-upload-button { -webkit-appearance: button; font: inherit; }
  details { display: block; }
  summary { display: list-item; }
  template { display: none; }
  [hidden] { display: none; }

  /*** RESET ***/
  a{text-decoration:none; color:inherit; cursor:pointer;}
  p{margin:0;}
  html {width: 100%; }
  body{
    font-family: Ubuntu, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.3;
    color: #666;
    width:100%;
    box-sizing: border-box;
  }

  /*** SITE STYLES ***/
  :root {
    --primary-color: #004c30;
    --secondary-color: #1cbf84;
    --third-color: #438274;
    --fourth-color: #8fb2a9;
    --primary-bg-color: #1f867c;
    --secondary-bg-color: #f9fafa;
    --menu-item-over-color: #c7c8d0;
    --sub-menu-item-secondary-bg-color: #94d5d3;
    --sub-menu-item-font-secondary-color: #06685b;
    --separator-color: #d1d2d4;
    --footer-bg-color: #121314;
    --bg-tile: url('/assets/imgs/bg-tile.png');
  }

  body {
    background-color: var(--secondary-bg-color);
    max-width: 100vw;
    overflow-x: hidden;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    max-width: 100vw;
    width: 100%;
  }

  .section-title {
    margin: 0;
    font-size: 1.8rem;
    color: var(--primary-color);
  }

  hr {
    border: 1px solid var(--separator-color);
  }

  .primary-button {
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.2rem;
    padding: .5em 1.2em;
    border: transparent;
    border-radius: 1.2em;
    cursor: pointer;
    transition: .3s;
  }

  .primary-button:hover {
    background-color: var(--secondary-color);
  }

  .primary-button:active, .primary-button:focus {
    outline: none;
    border: transparent;
  }

  .form-group {
    width: 100%;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: stretch;
  }

  .form-group .invalid-feedback {
    color: red;
    font-size: .9rem;
    font-weight: 300; 

  }

  .form-control {
    background: transparent;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: .5em;
    font-size: 1em;
    color: #000;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: unset;
  }

  .form-control.is-valid, .was-validated .form-control:valid {
    border-color: #28a745;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }

  .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  
  .form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40,167,69,.25);
  }

  .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}

  @media (min-width: 71.25em) {
    .section-title {
      font-size: 2rem;
    }
  }

/*--- MSGS ---*/
.msg-default, .msg-info, .msg-success, .msg-alert, .msg-warning {
  border:1px solid;
  border-radius:4px;
  margin:1.5em 0;
  padding:1em;
  text-align:left;
}

/*---BLUE-GREY---*/
.msg-default {
  background-color:#eceff1;
  border-color:#b0bec5;
  color:#607d8b;
}

/*---BLUE---*/
.msg-info {
  background-color:#e3f2fd;
  border-color:#90caf9;
  color:#2196f3;
}

/*---GREEN---*/
.msg-success {
  background-color:#e8f5e9;
  border-color:#a5d6a7;
	color:#4caf50;
}

/*---AMBER---*/
.msg-alert {
  background-color:#fff8e1;
  border-color:#ffe082;
	color:#ffc107;
}

/*---RED---*/
.msg-warning {
  background-color:#ffebee;
  border-color:#ef9a9a;
	color:#f44336;
}
`;