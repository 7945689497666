import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100vw;
  margin: 0 auto;
  padding: 4em 1em;
  justify-content: space-between;

  & .title {
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }

  & .image-container {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 100%;
    max-width: 100vw;

    & img {
      width: 22em;
      box-shadow: 1px 2px 10px;
    }
  }

  & div {
    max-width: 40.625em;
    margin: 0 auto;
  }

  & .content-text {
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.1rem;
  }

  & hr {
    margin-top: 0;
  }

  @media (min-width: 31.25em) {
    padding: 3em 2em;
  }

  @media (min-width: 68.125em) {
    max-width: 71.25em;

    & div {
      max-width: 100%;
      width: 60%;
    }

    & .title {
      margin-bottom: 0;
    }

    & .image-container {
      width: 40%;
    }
    & .content-text {
      margin-top: 0;
      padding: 0;
    }

    & .section-title {
      width: 100%;
    }
  }
`;