import styled from 'styled-components';

export const Footer = styled.footer`
  margin-top: auto;
  background: var(--footer-bg-color);
  display: flex;
  justify-content: center;
  padding: 2em 1em .5em;

  & p {
    font-size: 14px;
    margin-bottom: 0;
    color: #d5d5d5;
  }

  & h4 {
    margin-bottom: .5em;
  }
`;

export const Container = styled.div`
  max-width: 71.25em;
  width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;