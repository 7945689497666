import styled from 'styled-components';

export const Load = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  z-index: 99999;

  & div {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;        
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;      
    border: .8em solid var(--secondary-color);
    border-left: .8em solid var(--primary-color);        

    @keyframes load8 {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  }
`;