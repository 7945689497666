import React, { useState, useEffect, useRef } from 'react';
import { FaBars } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';

import { Header, Container, NavBar, NavMenu, MenuToggle } from './styles';
import { Menu } from './Menu'
import { useDocumentScrollThrottled } from '../../utils/functions'
import { NavLinkType, MenuItem } from '../../@types/site_header'

const NavLink: React.FC<NavLinkType> = ({isHome, item, content, onClick}) => {
  if (isHome && item.path) {
    return (
      <Scroll
        to={ item.path }
        spy={true}
        smooth={true}
        offset={-100}
        duration={800}
        className="nav-link"
        onClick={onClick}
      >
        { content } 
      </Scroll>
    );
  } else if (item.external) {
    return (
      <a href={item.route!} title={item.label} className="nav-link" target="_blank" rel="noopener noreferrer">{ content }</a>
    );
  } else {
    return (
      <Link to={item.route!} onClick={onClick} className="nav-link">
        { content } 
      </Link>
    );
  }
}

const SiteHeader: React.FC = () => {
  const location = useLocation().pathname;
  const [isSticky, setIsSticky] = useState<boolean | string>('absolute');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false); 
  const [itemActive, setItemActive] = useState<string>('Home');
  const wrapperRef = useRef(null);
  const toggleButtonRef = useRef(null);

  useDocumentScrollThrottled((callbackData: { previousScrollTop: any; currentScrollTop: any; }) => {
    const MINIMUM_SCROLL = 300;
    const TIMEOUT_DELAY = 50;
    const { currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setIsSticky(isMinimumScrolled ? true : 'absolute');
    }, TIMEOUT_DELAY);
  });

  // Close de Dropdown menu, if the user click anywhere in the screen
  const useOutsideAlerter = (ref: any, buttonRef: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any ) {
        if (ref.current && ref.current.getAttribute('data-active') && !ref.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
          setIsMenuOpen(false)
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, buttonRef]);
  }
  useOutsideAlerter(wrapperRef, toggleButtonRef)

  return (
    <Header data-sticky={location === '/' ? isSticky : 'relative'}>
      <Container>
        <div>
          <NavLink
            isHome={location === '/'}
            item={Menu[0]}
            content={<img src={'/assets/imgs' + (isSticky === true || location !== '/' ? '/aspecir-emprestimos-logo.png' : '/aspecir-emprestimos-logo-negativo.png')} className="logo" alt="Aspecir Empréstimos - Instituição financeira autorizada pelo banco central do brasil."/>}
          />
        </div>
        <NavBar>
          <NavMenu ref={wrapperRef} data-active={isMenuOpen}>
            {Menu.map((item:MenuItem, index) => (
              <li key={index} data-active={ itemActive === item.label }>
                <NavLink
                  isHome={location === '/'}
                  item={item}
                  onClick={() => setItemActive(item.label)}
                  content={item.icon ? <><item.icon />{" " + item.label}</> : item.label}
                />
              </li>
            ))}
          </NavMenu>
          <MenuToggle ref={toggleButtonRef} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FaBars />
          </MenuToggle>
        </NavBar>

      </Container>
    </Header>
  );
};

export default SiteHeader;