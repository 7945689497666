import { useEffect, useState } from "react";
import { throttle } from "lodash";

export const getEnv = (key: string, defaultValue?: string): string => {
  return process.env[key]?.toString() ?? (defaultValue || "");
};

export const setPhoneMask = (phone: string) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d\d)(\d)/g, "($1) $2");

  if (phone.length === 14) phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
  else phone = phone.replace(/(\d{4})(\d)/, "$1-$2");

  return phone;
};

export const useDocumentScrollThrottled = (callback: any) => {
  const [, setScrollPosition] = useState(0);
  let previousScrollTop = 0;

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } =
      document.documentElement || document.body;

    setScrollPosition((previousPosition) => {
      previousScrollTop = previousPosition;
      return currentScrollTop;
    });

    callback({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

  useEffect(() => {
    window.addEventListener("scroll", handleDocumentScrollThrottled);

    return () =>
      window.removeEventListener("scroll", handleDocumentScrollThrottled);
  }, [handleDocumentScrollThrottled]);
};

//Return the value of a parameter in an url
export const getQueryVariable = (variable: string) => {
  var query = window.location.search.substring(1);
  console.log(query); //"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};
