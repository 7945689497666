import styled from 'styled-components';

export const Container = styled.section`
  max-width: 71.25em;
  padding: 0 1em;
  margin: 0 auto;

  @media (max-width: 47.5em) {
    background-color: #fff;
  }
`;

export const ArticleWrapper = styled.div`
  margin: 2em auto;
  border-radius: .5em;
  background-color: #fff;

  & .article-content {
    font-size: .98em;
    color: #999;

    & p {
      margin-bottom: .7em;
    }

    & img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (min-width: 47.5em) {
    max-width: 85%;
    padding: 1em;
    box-shadow: 0 2px 15px 0 rgb(0 0 0 /.06);
  }
`;

export const ArticleHeader = styled.div`
  margin-bottom: 1.5em;

  & h1 {
    font-weight: 500;
  }

  & small {
    margin-top: 0;
  }
`;