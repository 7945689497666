import React, {useEffect} from 'react';

import { Wrapper, Container } from './styles'
import texts from '../../utils/texts'

const Products:React.FC = () => {

  useEffect(() => {
    document.title = texts.products.browser_title
  }, []);

  return (
    <Wrapper>
      <Container>
        <h2 className="section-title">{texts.products.title}</h2>
        <div dangerouslySetInnerHTML={{__html: texts.products.content}}></div>
      </Container>
    </Wrapper>
  );
}

export default Products;