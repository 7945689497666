import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  max-width: 71.25em;
  padding: 1em;
  margin: 1em auto;

  & h4 {
    margin-bottom: .3em;
  }
`;