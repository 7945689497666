import React, { useState, useEffect } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { FaFile } from "react-icons/fa";

import { Wrapper, Container, DropzoneContainer } from "./styles";
import consts from "../../utils/config";
import texts from "../../utils/texts";
import { FormData as Candidate } from "../../@types/work_apply";
import { Message } from "../../@types/others";

import Loader from "../../components/Loader";

const WorkApply: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message>({} as Message);
  const [, setValidationMessage] = useState<string>("");
  const [formData, setFormData] = useState<Candidate>({} as Candidate);

  useEffect(() => {
    document.title = texts.work_apply.browser_title;
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "application/pdf",
    multiple: false,
    noClick: true,
  });

  const curriculumFile = () => {
    if (formData.file) {
      return (
        <DropzoneContainer {...{ isValid: true }}>
          <span>
            <FaFile />{" "}
            <strong>{texts.work_apply.form.input_file.selected}</strong>{" "}
            {formData.file.name}
          </span>
        </DropzoneContainer>
      );
    } else {
      return (
        <DropzoneContainer
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
            isFocused,
          })}
        >
          <input {...getInputProps()} name="file" onChange={handleFields} />
          {!isDragActive && texts.work_apply.form.input_file.empty}
          {isDragActive &&
            !isDragReject &&
            texts.work_apply.form.input_file.valid}
          {isDragReject && texts.work_apply.form.input_file.invalid}
          <button className="primary-button" type="button" onClick={open}>
            {texts.work_apply.form.input_file.button}
          </button>
        </DropzoneContainer>
      );
    }
  };

  const handleForm = (e: any) => {
    e.preventDefault();

    console.log(formData);

    if (typeof formData.file === "undefined") {
      setMessage({
        message: texts.work_apply.messages.file_required,
        message_type: "warning",
      });
    } else {
      setIsLoading(true);

      let data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("file", formData.file);

      axios
        .post(consts.getApiSiteUrl() + "/job-apply", data)
        .then((response) => {
          setMessage({
            message: texts.work_apply.messages.submit_success,
            message_type: "success",
          });
          setFormData({} as Candidate);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          setMessage({
            message: texts.work_apply.messages.submit_error,
            message_type: "warning",
          });
          setIsLoading(false);
        });
    }
  };

  const handleFields = (e: any) => {
    if (e.target.type === "file") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    setValidationMessage("");
  };

  return (
    <Wrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <h2 className="section-title">{texts.work_apply.title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: texts.work_apply.description }}
          />

          <ValidationForm id="contactForm" onSubmit={(e: any) => handleForm(e)}>
            {message.message !== "" ? (
              <div className={"msg-" + message.message_type}>
                <span>{message.message}</span>
              </div>
            ) : null}
            <div className="form-group">
              <TextInput
                name="name"
                id="name"
                required
                errorMessage={{
                  required:
                    texts.work_apply.form.input_name.validation_required,
                }}
                className="form-control"
                placeholder={texts.work_apply.form.input_name.placeholder}
                autoComplete="off"
                onChange={handleFields}
              />
            </div>

            <div className="form-group">
              <TextInput
                name="email"
                id="email"
                type="email"
                required
                errorMessage={{
                  required:
                    texts.work_apply.form.input_email.validation_required,
                  type: texts.work_apply.form.input_email.validation_type,
                }}
                className="form-control"
                placeholder={texts.work_apply.form.input_email.placeholder}
                autoComplete="off"
                onChange={handleFields}
              />
            </div>
            <div className="form-group">{curriculumFile()}</div>

            <button type="submit" className="primary-button">
              {texts.work_apply.form.submit}
            </button>
          </ValidationForm>
        </Container>
      )}
    </Wrapper>
  );
};

export default WorkApply;
