import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  padding: 2em 0 4em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  width: 100%;
  max-width: 71.25em;
  justify-content: space-between;
  padding: 0 1em;

  & .contact-information {
    width: 100%;
    max-width: 42em;
    padding: 0 .1em;

    & .get-in-touch {
      margin-top: 10px;

      & h3 {
        margin-bottom: 0;
      }
    }
    
    & .contact-box {
      display: flex;
      align-items: center;
      margin: 1.5em 0 2em;

      & .icon {
        width: 2.6em;
        height: 2.6em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 .5em 0 0;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        transition: .3s;
      }

      & .icon:hover {
        color: #fff;
        background-color: var(--primary-color);
      }

      & h4 {
        font-size: 1.4rem;
        margin: 0;
      }

      & p, a {
          font-size: .8rem;
        }
    }
  }

  & #contactForm {
    width: 100%;
    max-width: 42em;
  }

  @media (min-width: 71.25em) {
    flex-direction: row;

    & .contact-information {
      width: 50%;
      max-width: auto;

      & .contact-box {

        & .icon {
          width: 3.5em;
          height: 3.5em;
          margin: 0 2em;
        }

        & p {
          font-size: 1rem;
        }
      }
    }

    & #contactForm {
      width: 50%;
      max-width: 27.81em;
    }
  }
`;