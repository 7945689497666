import styled from 'styled-components';

export const MenuToggle = styled.button`
  display: block;
  background-color: transparent;
  border: transparent;
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  transition: .3s;
  margin-left: auto;
  padding: 0;
  height: 40px;
  width: 40px;

  &:hover {
    color: var(--secondary-color);
  }

  &:active, &:focus {
    outline: none;
    border: transparent;
  }

  & .nav-link {
    cursor: pointer;
    display: block;
    font-size: 1.5rem;
  }

  @media (min-width: 68.125em) {
    display: none;
  }
`;

export const Header = styled.header`
  position: absolute;
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;

  &[data-sticky="true"] {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);
    animation: showStickyMenu .5s ease-in-out 0s 1 normal none running;

    & li a {
      color: #333;
    }

    ${MenuToggle} {
      color: #333;

      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  &[data-sticky="relative"] {
    position: relative;
    background-color: #fff;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);

    & li a {
      color: #333;
    }

    ${MenuToggle} {
      color: #333;

      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  &[data-sticky="absolute"] {
    position: absolute;
    animation: hideStickyMenu 1s;
  }

  @keyframes showStickyMenu {
    0% { top: 0; opacity: 1; position: absolute; }
    50% { top: -5em; opacity: 0; background-color: transparent; }
    100% { top: 0; opacity: 1; position: fixed; background-color: #fff; }
  }

  @keyframes hideStickyMenu {
    0% { top: 0; position: fixed; opacity: 1; background-color: #fff; }
    50% {  top: -5em; opacity: 0; background-color: transparent; }
    100% { top: 0; position: absolute; opacity: 1; }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 2em;
  max-width: 81.25em;
  justify-content: space-between;

  & .logo {
    width: 250px;
  }

  @media (min-width: 68.125em) {
    padding: 0;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  align-items: center;
  padding: 11px 16px;
`;

export const NavMenu = styled.ul`
  display: none;
  align-items: left;
  list-style: none;
  color: var(--primary-color);
  user-select: none;
  position: absolute;
  background-color: #fff;
  padding: 0;

  & li {
    height: auto;

    & a {
      cursor: pointer;
      display: block;
      line-height: 2.5rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: #fff;
      transition: .3s;
    } 

    &:hover a, &[data-active="true"] a {
      color: var(--secondary-color);
    }
  }

  &[data-active="true"] {
    display: flex;
    flex-direction: column;
    top: 3.5em;
    right: 2.5em;
    border-radius: .5em;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);

    & li {
      & a {
        color: #333;
        padding: 0 2em;
      }
    }
  }

  @media (min-width: 68.125em) {
    position: relative;
    display: flex;
    background-color: transparent;

    & li {
      margin-left: 1.2em;

      & a {
        line-height: 2rem;
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 73.75em) {
    & {
      & li {
        margin-left: 1.5em;

        & a {
          line-height: 2.5rem;
          font-size: 1.1rem;
        }
      }
    }
  }
`;


export const NavIcon = styled.li`
  font-size: 2rem;
  line-height: 2rem;
  cursor: pointer;
  fill: currentColor;

  & svg {
    fill: currentColor;
    color: #fff;
    transition: .3s;
  }
  
  &:hover svg {
    fill: currentColor;
    color: var(--secondary-color);
  }
`;