import React from 'react';
import { Wrapper, Container, NewFrame } from './styles';
import { Link } from 'react-router-dom'
import texts from '../../../utils/texts';

export type NewType = {
  id?: number,
  title?: string,
  preview?: string,
  created_at?: Date,
  image_description?: string,
  image?: {
    name: string,
    url: string
  }
}

const News:React.FC<NewType[]> = (news) => {
  let cTexts = texts.home.news;

  const newImage = (item:NewType) => {
    return item.image ? <img src={item.image.url} alt={item.image_description} /> : "";
  };

  return (
    <Wrapper id="news">
      <h2 className="section-title">{cTexts.title}</h2>
      <Container>
        {Object.values(news).map((item, index) => (
          <NewFrame>
            <div className="img-container">{ newImage(item) }</div>
            <div className="new-title">
              <h3>{item.title}</h3>
            </div>
            <small>{item.created_at}</small>
            <p className="new-preview">{item.preview}...</p>

            <Link to={'/noticias/' + item.id} className="continue-link">{cTexts.continue_button}</Link>
          </NewFrame>
        ))}
      </Container>
      <Link to="/noticias" className="primary-button">{cTexts.button}</Link>
    </Wrapper>
  );
};

export default News;