import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const IndiponibilityChatDiv = styled.div`
position: fixed;
bottom: 25px;
left: 25px;
z-index: 1000;
  `;
