import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import consts from './utils/config'

import SiteHeader from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';

import GlobalStyles from './styles/GlobalStyles'
import Home from './pages/Home'
import Products from './pages/Products'
import News from './pages/News'
import Transparency from './pages/Transparency'
import WorkApply from './pages/WorkApply'

export default function App() {
    return (
      <>
        <BrowserRouter>
          <SiteHeader />
          <Switch>
            <Route exact path="/" render={(props) => <Home />} />
            <Route exact path="/produtos" render={(props) => <Products />} />
            <Route exact path="/noticias" render={(props) => <News />} />
            <Route exact path="/noticias/:id" render={(props) => <News />} />
            <Route exact path="/transparencia" render={(props) => <Transparency />} />
            <Route exact path="/trabalhe-conosco" render={(props) => <WorkApply />} />
          </Switch>
          <SiteFooter companyName={consts.APP_NAME} />
        </BrowserRouter>
        <GlobalStyles />
      </>
    )
}
