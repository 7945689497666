import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  padding: 2em 0 2em;
  justify-content: center;
  background-image: url('/assets/imgs/bg.png');
  overflow: hidden;
  box-sizing: border-box;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 1em;
`;

export const ProductFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 12em;
  text-align: center;
  padding: 1em .5em 0;
  margin: 2em 1em 1em;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 /.06);
  transition: .2s;
  cursor: pointer;

  &:hover {
    margin: 1.5em 1em 1.5em;
  }

  & img {
    max-width: 50px;
  }
`;