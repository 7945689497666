import React from 'react';
import { Link } from 'react-router-dom'

import { Wrapper, ProductsContainer, ProductFrame } from './styles'
import texts from '../../../utils/texts'

const Products:React.FC = () => {
  let cTexts = texts.home.products;

  return (
    <Wrapper id="products">
      <h2 className="section-title">{cTexts.title}</h2>
      <ProductsContainer>
        {Object.values(cTexts.products).map((item, index) => (
          <Link to="/produtos">
            <ProductFrame>
              <img src={item.image} alt={item.title}/>
              <h3>{item.title}</h3>
            </ProductFrame>
          </Link>
        ))}
      </ProductsContainer>

      <Link to="/produtos" className="primary-button">Mais detalhes</Link>
    </Wrapper>
  );
};

export default Products;