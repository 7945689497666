import React from 'react';
import { Footer, Container, ContainerRow } from './styles'

interface FooterProps {
  companyName: string
}

const SiteFooter:React.FC<FooterProps> = ({companyName}) => {

  return (
    <Footer>
      <Container>
        <div>
          <div>
            <p>Copyright @ {new Date().getFullYear()} {companyName}. Todos os direitos reservados.</p>
          </div>
        </div>
        <ContainerRow>
          <div>
            <h4>Ouvidoria</h4>
            <p><strong>Telefone:</strong> 0800 067 5757</p>
            <p><strong>Site:</strong> <a href="https://www.abscm.com.br/ouvidoria" target="_blank" rel="noopener noreferrer">https://www.abscm.com.br/ouvidoria</a></p>
          </div>
          <div>
            <h4>Denúncia</h4>
            <p><strong>E-mail:</strong> denuncia@aspeciremprestimos.com.br</p>
          </div>
        </ContainerRow>
      </Container>
    </Footer>
  );
};

export default SiteFooter;