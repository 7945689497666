import styled from 'styled-components';

export const Frame = styled.div`
  background-color: #fff;
  border-radius: .5em;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 /.06);
  margin: 1em;
  display: flex;
  align-items: stretch;
  max-width: 35em;
  
  & .icon-container {
    border-right: 1px solid #f1f1f1;
    height: 100%;
    padding: 0 2em;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      font-size: 2rem;
      color: #999;
      transition: .3s;
    }
  }

  &:hover .icon-container svg {
    color: var(--primary-color);
  }

  & .file-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  & .file-information {
    & div {
      padding: .3em .5em;
    }
  }

  &.frame-simple {
    & .file-description {
      display: none;
    }

    & .file-information {
      display: flex;
      flex-direction: column;

      & div {
        border-bottom: 1px solid #f1f1f1;
      }

      & div:last-child {
        border-bottom: transparent;
      }
    }
  }

  &.frame-complete {
    & .file-description {
      padding: .3em .5em;

      & small {
        font-size: .75rem;
      }
    }

    & .file-information {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #f1f1f1;

      & div {
        border-left: 1px solid #f1f1f1;
      }

      & div:first-child {
        border-left: transparent;
        margin-right: auto;
      }
    }
  }
`;