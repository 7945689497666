import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2em;
`;

export const Container = styled.div`
  max-width: 71.25em;
  margin: 1.5em auto;
  padding: 0 .5em;
`;

const getColor = (props: any) => {
  if (props.isDragAccept || props.isValid) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive || props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em .5em;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: .3s;

  &:hover {
    background-color: #fff;
  }

  & button {
    margin-top: .7em;
    padding: .4em 1em;
    background-color: #f1f1f1;
    color: #555;
    font-size: 1.1rem;

    &:hover {
      color: #fff;
    }
  }
`;