import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, ArticleWrapper, ArticleHeader } from "./style";
import Loader from "../../Loader";
import consts from "../../../utils/config";
import { ArticleType } from "../../../@types/news";

const Article: React.FC<{ id: string }> = ({ id }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<ArticleType>({} as ArticleType);

  useEffect(() => {
    // Return site data from the API
    axios
      .get(consts.getApiNewUrl() + "/" + id)
      .then((resp) => {
        console.log(resp);

        setArticle(resp.data.data.post);
        setIsLoading(false);
      })
      .catch((resp) => console.log(resp));
  });

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Container>
        <ArticleWrapper>
          <ArticleHeader>
            <h1 className="section-title">{article.title}</h1>
            <small>{article.created_at!}</small>
          </ArticleHeader>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: article.text! }}
          ></div>
        </ArticleWrapper>
      </Container>
    );
  }
};

export default Article;
