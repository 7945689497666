import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2em;
`;

export const Container = styled.div`
  max-width: 71.25em;
  margin: 1.5em auto;
`;

export const FilesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const TransparencyText = styled.div`
  margin-bottom: 1.5em;
  padding: 0 1em;

  & h2 {
    border-bottom: 4px solid var(--primary-color);
  }

  & div p {
    margin-top: 1em;
    text-align: justify;
    text-justify: inter-word;
  }
`;